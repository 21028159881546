import { default as agbh99VXTzrOeMeta } from "/vercel/path0/layers/base/pages/agb.vue?macro=true";
import { default as daten_45korrigierenrO0Ksm2GjlMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten-korrigieren.vue?macro=true";
import { default as fin3x1wlAOIRDMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/fin.vue?macro=true";
import { default as generellcpwFBDX6zEMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/generell.vue?macro=true";
import { default as sicherheitscode_45kennzeichen_45hintenq47BG2ldTKMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten.vue?macro=true";
import { default as sicherheitscode_45kennzeichen_45vornedZoc2z3iJuMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne.vue?macro=true";
import { default as sicherheitscode_45zb11iCijwbB8VMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-zb1.vue?macro=true";
import { default as indexsBk8334br6Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/index.vue?macro=true";
import { default as orderK456w9mH1VMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/order.vue?macro=true";
import { default as fertigYCJr69cVHpMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/fertig.vue?macro=true";
import { default as indexco3tI9qwy6Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/index.vue?macro=true";
import { default as sc_45hintenHFfWWiaAm9Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-hinten.vue?macro=true";
import { default as sc_45vorn5euoZ1U7wvMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-vorn.vue?macro=true";
import { default as sc_45zb19z9DdbEWn4Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-zb1.vue?macro=true";
import { default as zb1ztV6QSFErLMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/zb1.vue?macro=true";
import { default as vorbereitungNBPdgYneSVMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/vorbereitung.vue?macro=true";
import { default as dataLcJyhK4qeaMeta } from "/vercel/path0/abmeldung-digital/pages/data.vue?macro=true";
import { default as datenschutzjl1RybKV6eMeta } from "/vercel/path0/layers/base/pages/datenschutz.vue?macro=true";
import { default as impressumlDRZsWB8OXMeta } from "/vercel/path0/layers/base/pages/impressum.vue?macro=true";
import { default as indexzGueuVfsTSMeta } from "/vercel/path0/abmeldung-digital/pages/index.vue?macro=true";
import { default as _91district_93V8n3FaDfayMeta } from "/vercel/path0/abmeldung-digital/pages/landkreis/[district].vue?macro=true";
import { default as landkreiseBFDegqXARqMeta } from "/vercel/path0/abmeldung-digital/pages/landkreise.vue?macro=true";
import { default as logind9zDZxtd06Meta } from "/vercel/path0/abmeldung-digital/pages/login.vue?macro=true";
import { default as _91city_93YrIX4WPlyrMeta } from "/vercel/path0/abmeldung-digital/pages/stadt/[city].vue?macro=true";
import { default as staedteNTE77ABHD1Meta } from "/vercel/path0/abmeldung-digital/pages/staedte.vue?macro=true";
import { default as testR71Q4wgxomMeta } from "/vercel/path0/abmeldung-digital/pages/test.vue?macro=true";
import { default as wartungHY2kRFLwbMMeta } from "/vercel/path0/abmeldung-digital/pages/wartung.vue?macro=true";
import { default as widerrufsrechtQmYuV9tGV4Meta } from "/vercel/path0/layers/base/pages/widerrufsrecht.vue?macro=true";
export default [
  {
    name: "agb",
    path: "/agb",
    meta: agbh99VXTzrOeMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/agb.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-korrigieren",
    path: "/ausserbetriebsetzung/daten-korrigieren",
    meta: daten_45korrigierenrO0Ksm2GjlMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten-korrigieren.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-fin",
    path: "/ausserbetriebsetzung/daten/fin",
    meta: fin3x1wlAOIRDMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/fin.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-generell",
    path: "/ausserbetriebsetzung/daten/generell",
    meta: generellcpwFBDX6zEMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/generell.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-kennzeichen-hinten",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten",
    meta: sicherheitscode_45kennzeichen_45hintenq47BG2ldTKMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-kennzeichen-vorne",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne",
    meta: sicherheitscode_45kennzeichen_45vornedZoc2z3iJuMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-zb1",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-zb1",
    meta: sicherheitscode_45zb11iCijwbB8VMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-zb1.vue")
  },
  {
    name: "ausserbetriebsetzung",
    path: "/ausserbetriebsetzung",
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/index.vue")
  },
  {
    name: "ausserbetriebsetzung-order",
    path: "/ausserbetriebsetzung/order",
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/order.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-fertig",
    path: "/ausserbetriebsetzung/upload/fertig",
    meta: fertigYCJr69cVHpMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/fertig.vue")
  },
  {
    name: "ausserbetriebsetzung-upload",
    path: "/ausserbetriebsetzung/upload",
    meta: indexco3tI9qwy6Meta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/index.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-sc-hinten",
    path: "/ausserbetriebsetzung/upload/sc-hinten",
    meta: sc_45hintenHFfWWiaAm9Meta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-hinten.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-sc-vorn",
    path: "/ausserbetriebsetzung/upload/sc-vorn",
    meta: sc_45vorn5euoZ1U7wvMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-vorn.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-sc-zb1",
    path: "/ausserbetriebsetzung/upload/sc-zb1",
    meta: sc_45zb19z9DdbEWn4Meta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-zb1.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-zb1",
    path: "/ausserbetriebsetzung/upload/zb1",
    meta: zb1ztV6QSFErLMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/zb1.vue")
  },
  {
    name: "ausserbetriebsetzung-vorbereitung",
    path: "/ausserbetriebsetzung/vorbereitung",
    meta: vorbereitungNBPdgYneSVMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/vorbereitung.vue")
  },
  {
    name: "data",
    path: "/data",
    component: () => import("/vercel/path0/abmeldung-digital/pages/data.vue")
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    meta: datenschutzjl1RybKV6eMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/datenschutz.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    meta: impressumlDRZsWB8OXMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexzGueuVfsTSMeta || {},
    component: () => import("/vercel/path0/abmeldung-digital/pages/index.vue")
  },
  {
    name: "landkreis-district",
    path: "/landkreis/:district()",
    meta: _91district_93V8n3FaDfayMeta || {},
    component: () => import("/vercel/path0/abmeldung-digital/pages/landkreis/[district].vue")
  },
  {
    name: "landkreise",
    path: "/landkreise",
    meta: landkreiseBFDegqXARqMeta || {},
    component: () => import("/vercel/path0/abmeldung-digital/pages/landkreise.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/abmeldung-digital/pages/login.vue")
  },
  {
    name: "stadt-city",
    path: "/stadt/:city()",
    meta: _91city_93YrIX4WPlyrMeta || {},
    component: () => import("/vercel/path0/abmeldung-digital/pages/stadt/[city].vue")
  },
  {
    name: "staedte",
    path: "/staedte",
    meta: staedteNTE77ABHD1Meta || {},
    component: () => import("/vercel/path0/abmeldung-digital/pages/staedte.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/abmeldung-digital/pages/test.vue")
  },
  {
    name: "wartung",
    path: "/wartung",
    component: () => import("/vercel/path0/abmeldung-digital/pages/wartung.vue")
  },
  {
    name: "widerrufsrecht",
    path: "/widerrufsrecht",
    meta: widerrufsrechtQmYuV9tGV4Meta || {},
    component: () => import("/vercel/path0/layers/base/pages/widerrufsrecht.vue")
  }
]